import Layout from 'components/Layout';
import Custom404 from 'components/404';

import { getPage404 } from 'lib/queries/getPage404';
import FloatingActionButton from 'components/FloatingActionButton';
import ContentZone from 'components/ContentZone';

const Page404 = ({ data }) => {
    return (
        <Layout {...data}>
            <main id="main">
                {data?.custom_404 && <Custom404 {...data.custom_404} />}
                {data?.content_zone && <ContentZone {...data.content_zone} />}
                {data?.custom_404?.floating_action_button && (
                    <FloatingActionButton
                        {...data.custom_404.floating_action_button}
                    />
                )}
            </main>
        </Layout>
    );
};

export default Page404;

export const getStaticProps = async (context) => {
    const data = await getPage404(context);
    return {
        props: {
            data,
        },
        revalidate: 60,
    };
};
