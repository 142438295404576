import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import RichText from 'components/RichText';
import Icon from 'components/Icon';
import classNames from 'classnames';

const Custom404 = ({
    heading,
    description,
    placeholder,
    searching_text,
    footnote,
    search,
    rich_text_heading,
    timeout,
}) => {
    const [searchQuery, setSearchQuery] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (timeout) {
            setTimeout(() => {
                router.push('/');
            }, timeout * 1000);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSearching(true);
        router.push(`/search?q=${searchQuery}`);
    };

    return (
        <section
            className={classNames('page404', {
                section:
                    rich_text_heading?.value ||
                    heading ||
                    !search ||
                    description?.value ||
                    footnote?.value,
            })}
        >
            <div className="container">
                {rich_text_heading?.value && (
                    <RichText
                        className="page404__heading"
                        data={rich_text_heading}
                    />
                )}
                {!rich_text_heading?.value && heading && (
                    <h1 className="page404__heading">{heading}</h1>
                )}
                <RichText className="page404__description" data={description} />
                {!search ? (
                    <form onSubmit={handleSubmit}>
                        <div className="form__group">
                            <div className="form__validation">
                                <input
                                    name="search"
                                    type="text"
                                    className="form__input form__input--search page404__search"
                                    placeholder={placeholder}
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)
                                    }
                                    autoComplete="off"
                                />
                                <Icon
                                    icon={'fa-solid fa-magnifying-glass'}
                                    className="form__search"
                                />
                            </div>
                            {isSearching && <small>{searching_text}</small>}
                        </div>
                    </form>
                ) : null}
                <RichText className="page404__footnote" data={footnote} />
            </div>
        </section>
    );
};

export default Custom404;
